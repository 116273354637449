import shopifyWarehouseGenericDefaults from '../shopify-warehouse-generic/config.js';
import {
  getVehicleString,
  updateVehicleVisualization,
  setStoreConfig,
} from '../_auto-sync/autosyncVisualizer.js';

const { baseFields, extraFields } = shopifyWarehouseGenericDefaults.fitmentSearch;

const wheelsFields = shopifyWarehouseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyWarehouseGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const baseUpdateCallback = shopifyWarehouseGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;
let searchResultsVehicleVisualizer = null;

function updateCallback() {
  baseUpdateCallback?.();

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  searchResultsVehicleVisualizer = updateVehicleVisualization({
    autoSyncVisualizer: searchResultsVehicleVisualizer,
    autoSyncConfig: {
      elId: 'cm_vehicle-visualization',
      height: '150px',
      vehicleImageSize: 640,
      vehicleAngles: [32],
      showColorPicker: false,
    },
    vehicleString,
  });
}

const baseInitFunc = shopifyWarehouseGenericDefaults.InitFunc;
const InitFunc = () => {
  baseInitFunc?.();
  setStoreConfig('autocareperformance', ['Year', 'Make', 'Model', 'Submodel']);
};

const onVehicleDiscarded = () => {
  [...window.document.body.classList]
    .filter((cl) => cl.startsWith('cm_') && (cl.endsWith('-visualized') || cl.endsWith('-failed')))
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });
};

export default {
  includes: ['shopify-warehouse-generic'],
  ...shopifyWarehouseGenericDefaults,
  InitFunc,
  fitmentSearch: {
    ...shopifyWarehouseGenericDefaults.fitmentSearch,
    onVehicleDiscarded,
  },
  Widgets: [
    ...shopifyWarehouseGenericDefaults.Widgets.filter(
      (w) => !['FitmentTable', 'SearchResult', 'FacetBarWheels', 'FacetBarTires'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'VehicleInfo',
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'VehicleWheelDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/vehicleWheelDialog',
    },
    {
      name: 'VehicleWheelDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'VehicleWheelDialog',
    },
  ],
};
