//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-296:_5672,_5436,_2036,_1824,_9558,_7696,_4660,_5644;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-296')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-296', "_5672,_5436,_2036,_1824,_9558,_7696,_4660,_5644");
        }
      }catch (ex) {
        console.error(ex);
      }